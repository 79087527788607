import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PrivateRoute, PublicRoute } from "./PrivateAuth";
import "swiper/css";
import "./App.css";
import "./components/Auth/Auth.css";
import Home from "./components/public/Home";
import Footer from "./components/common/Footer";
import Shop from "./components/public/Shop";
import { Header } from "./components/common/Header";
import ProductDetail from "./components/public/ProductDetail/ProductDetail";
import ScrollToTop from "./ScrollToTop";
import { guestLogin } from "./components/API/fakebackend";
import { v6 as uuidv6 } from "uuid";
import MyOrder from "./components/public/MyOrder/MyOrder";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import Cart from "./components/public/Cart/Cart";
import Review from "./components/public/ProductDetail/Review";
import Profile from "./components/private/Profile";
import AboutUs from "./components/common/AboutUs";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import RefundPolicy from "./components/common/RefundPolicy";
import TermsCondition from "./components/common/Terms&Condition";
import ShippingPolicy from "./components/common/ShippingPolicy";

export const isLogIn = () => {
  const data2 = JSON.parse(localStorage.getItem("authUser"));

  if (data2 && data2?.data?.user?._id) {
    return { isToken: true };
  } else {
    return { isToken: false };
  }
};

export const logout = () => {
  localStorage.clear();
  window.open("/", "_self");
};

export const isLoggedIn = async (cookies, setCookie) => {
  try {
    let UID = cookies.device_id;
    if (!UID) {
      UID = uuidv6();
      setCookie("device_id", UID, { path: "/" });
    }
    const value = {
      device_id: UID,
    };
    const result = await guestLogin(value);
    if (result.result === 1) {
      localStorage.setItem("authUser", JSON.stringify(result));
      window.dispatchEvent(new Event("storageChange"));
    } else {
      throw new Error("Guest login failed");
    }
  } catch (error) {
    console.error("Error in isLoggedIn:", error);
    // Handle the error as needed, e.g., log it or show an error message
  }
};

export const formatNumberWithCommas = (number) => {
  if (number == null) {
    return number;
  }

  let numStr = parseFloat(number).toFixed(2);

  let parts = numStr.split(".");

  let integerPart = parts[0];
  let lastThreeDigits = integerPart.slice(-3);
  let remainingDigits = integerPart.slice(0, -3);
  let formattedInteger =
    remainingDigits !== ""
      ? remainingDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        lastThreeDigits
      : lastThreeDigits;

  let formattedNumber = formattedInteger + "." + parts[1];

  return formattedNumber;
};

function App() {
  const [cookies, setCookie] = useCookies(["device_id"]);

  // useEffect(() => {
  //   const authUser = localStorage.getItem("authUser");
  //   const authCookie = cookies?.some(
  //     (cookie) => cookie.name === "yourCookieName"
  //   );

  //   if (!authUser && !authCookie) {
  //     isLoggedIn(cookies, setCookie);
  //   }
  // }, [cookies, setCookie]);

  useEffect(() => {
    const authUser = localStorage.getItem("authUser");
    const authCookie = cookies.device_id;

    if (!authUser || !authCookie) {
      isLoggedIn(cookies, setCookie);
    }
  }, [cookies, setCookie]);

  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      var Tawk_API = Tawk_API || {},
        Tawk_LoadStart = new Date();
      (function () {
        var s1 = document.createElement("script"),
          s0 = document.getElementsByTagName("script")[0];
        s1.async = true;
        s1.src = "https://embed.tawk.to/66a71f6dbecc2fed692c4a98/1i3ud5h0h";
        s1.charset = "UTF-8";
        s1.setAttribute("crossorigin", "*");
        s0.parentNode.insertBefore(s1, s0);
      })();
    }
  });

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path={"/profile"} exact element={<Profile />} />
          </Route>
          <Route path={"/"} exact element={<Home />} />
          <Route path={"/collections/:id?"} exact element={<Shop />} />
          <Route path={"/product/:id"} exact element={<ProductDetail />} />
          <Route path={"/product/review"} exact element={<Review />} />
          <Route path={"/cart"} exact element={<Cart />} />
          <Route path={"/my-order"} exact element={<MyOrder />} />
          <Route path={"/about-us"} exact element={<AboutUs />} />
          <Route path={"/privacy-policy"} exact element={<PrivacyPolicy />} />
          <Route path={"/refund-policy"} exact element={<RefundPolicy />} />
          <Route path={"/shipping-policy"} exact element={<ShippingPolicy />} />
          <Route
            path={"/terms-and-condition"}
            exact
            element={<TermsCondition />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
